<template>
	<div class="s">
		<div class="s-item" v-for="(item,index) in list" :key="index">
			<div class="s-left">
				<lida-table
					:pagination="false" 
					:height="300" 
					:data="item.shipItemResultList"
					border
					ref="table">
						<lida-table-column label="产品信息" fixed>
							<template #default="scope">
								<div class="g-info">
									<div class="g-icon">
										<img :src="scope.row.src"/>
									</div>
									<div class="g-right">
										<div class="g-r-title">
											{{scope.row.productName}}
											<div class="label" :title="scope.row.brandName">{{scope.row.brandName}}</div>
										</div>
										<div class="g-r-data">
											{{scope.row.barCode || '无'}}
											<span>|</span>
											{{scope.row.seriesName || '无'}}
											<span>|</span>
											{{scope.row.skuNumber || '无'}}
										</div>
									</div>
								</div>
							</template>
						</lida-table-column>
						<lida-table-column label="订货号" prop="articleNo" />
						<lida-table-column label="采购数量" prop="purchaseQty" />
						<lida-table-column label="发货数量" prop="shipQty" />
						<!-- <lida-table-column label="未发货数量" prop="notNumber" /> -->
				</lida-table>
			</div>
			<div class="s-right">
				<div class="s-t">
					<div class="s-row-title">发货信息：</div>
					<div class="s-row">
						<div class="s-col">
							<span>发货时间:</span>
							<p>{{item.shipTime}}</p>
						</div>
						<div class="s-col">
							<span>快递单号:</span>
							<p>{{item.courierNumber}}</p>
						</div>
					</div>
					<div class="s-row" v-if="item.remarks || (item.files && item.files.length)">
						<div class="s-col" v-if="item.remarks">
							<span>发货备注:</span>
							<p>{{item.remarks}}</p>
						</div>
						<div class="s-col" v-if="item.files && item.files.length">
							<span>发货资料:</span>
							<el-image
								style="width: 160px; height: 160px;object-fit: cover;"
								:src="item.files.length ? item.files[0].url : ''"
								:preview-src-list="[item.files.length ? item.files[0].url : '']"
								fit=""
							></el-image>
						</div>
					</div>
					<div class="s-row">
						<div class="s-col">
							<span>快递公司:</span>
							<p>{{getCourierCompany(item.courierCompany)}}</p>
						</div>
						<div class="s-col" v-if="item.courierCompany === 'shunfeng'">
							<span>手机号:</span>
							<p>{{item.courierPhone}}</p>
						</div>
					</div>
					<template v-if="item.receiptTime">
						<div class="s-row-title" style="padding-top: 20px;">收货信息：</div>
						<div class="s-row">
							<div class="s-col">
								<span>收货时间:</span>
								<p>{{item.receiptTime}}</p>
							</div>
							<div class="s-col">
								<span>备注:</span>
								<p>{{item.receiptRemarks}}</p>
							</div>
						</div>
					</template>
				</div>
				<div class="s-btn">
					<el-button size="mini" type="primary" @click="seeLogistics(item)">查看物流</el-button>
					<el-button size="mini" type="warning" @click="confirmReceiving(item)" v-if="isReceiving && item.isReceipt === '0'">确认收货</el-button>
				</div>
			</div>
		</div>
	</div>
	<!-- 查看物流弹框 -->
	<see-logistics-dialog
		v-model="logisticsShow"
		:info="activeRow"
	/>
</template>

<script>
	import seeLogisticsDialog from '../dialog/seeLogistics.vue'  //查看物流弹框
	import { runNumber } from '@/common/js/common.js'
	import { mapState, mapActions } from 'vuex'
	import { ElMessageBox, ElMessage } from 'element-plus'
	import moment from 'moment'
	export default{
		data(){
			return{
				logisticsShow:false,  //查看物流弹框的隐藏显示
				list:[],
				activeRow:null,  //选中的row
			}
		},
		props:['orderInfo','info'],
		components:{
			seeLogisticsDialog,
		},
		computed:{
			...mapState('purchaseMudule',[
				'courierCompany',  //快递公司
			]),
			...mapState([
				'userInfo',
			]),
			isReceiving(){
				return this.info?.inquiryRequestVO?.buyerId === this.userInfo?.userId;
			}
		},
		methods:{
			...mapActions('mysaleMudule',[
				'purchaseReceipt',  //确认收货
			]),
			seeLogistics(row){ //查看物流
				this.activeRow = row;
				this.logisticsShow = true;
			},
			init(){
				var list = this.orderInfo && this.orderInfo.purchaseShipResultVOList ? this.orderInfo.purchaseShipResultVOList : [];
				list = JSON.parse(JSON.stringify(list));
				list.map(item => {
					item.files = JSON.parse(item.files || '[]');
					if(item.receiptTime){
						item.receiptTime = moment(parseInt(item.receiptTime)).format('YYYY-MM-DD HH:mm:ss');
					}
				})
				this.list = list;
			},
			getCourierCompany(type){ //获取快递公司名称
				return this.courierCompany.some(v => v.type === type) ?
					this.courierCompany.filter(v => v.type === type)[0].title :
					'';
			},
			confirmReceiving(row){ //确认收货
				console.log(row);
				ElMessageBox.prompt('请输入收货备注', '确认收货', {
					confirmButtonText: '确认',
					cancelButtonText: '取消',
				}).then(({ value }) => {
					this.purchaseReceipt({
						documentId:this.orderInfo.documentId,
						receiptRemarks:value,
						shipId:row.id,
					}).then(res => {
						let {code,data} = res.data;
						if(code === '0'){
							ElMessage({
								type:'success',
								message:'操作成功',
							})
							//操作成功 刷新详情数据
							this.$emit('bjSuccess');
						}
					})
				}).catch(() => {})
			}
		},
		created(){
			this.init();
		},
		watch:{
			orderInfo(){
				this.init();
			},
			info(){
				this.init();
			}
		}
	}
</script>

<style scoped lang="less">
	.s{
		.s-item{
			margin-bottom: 15px;
			display: flex;
			align-items: stretch;
			background: #F9F9F9;
			box-sizing: border-box;
			padding: 10px;
			.s-left{
				max-width: 60%;
				min-width: 60%;
			}
			.s-right{
				flex: 1;
				box-sizing: border-box;
				padding: 0 10px;
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				.s-row{
					display: flex;
					align-items: stretch;
					padding-bottom: 20px;
					.s-col{
						display: flex;
						flex: 1;
						span{
							margin-right: 10px;
						}
						p,.s-text{
							flex: 1;
						}
					}
				}
				.s-btn{
					display: flex;
					justify-content: flex-end;
					padding-top: 20px;
				}
			}
		}
		.s-item:last-of-type{
			margin-bottom: 0;
		}
	}
	.s-row-title{
		padding-bottom: 20px;
		color: @dh-color;
		font-weight: bold;
	}
</style>
