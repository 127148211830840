<template>
	<div class="r">
		<div class="r-title">收款凭证</div>
		<div class="r-content">
			<el-image
			  style="width: 200px; height: 200px"
			  :src="item"
			  :preview-src-list="list"
			  :initial-index="index"
			  v-for="(item,index) in list"
			  :key="index"
			>
			</el-image>
		</div>
	</div>
</template>

<script>
	export default{
		data(){
			return{
				
			}
		},
		props:['orderInfo'],
		methods:{
			init(){
				var list = this.orderInfo.payFiles ,
				arr = [];
				list.map(item => {
					arr.push(item.url);
				})
				this.list = arr;
			}
		},
		created(){
			this.init();
		},
		watch:{
			orderInfo(){
				this.init();
			}
		}
	}
</script>

<style scoped lang="less">
	.r{
		.r-title{
			color: @dh-color;
			font-weight: bold;
			padding-top: 10px;
		}
		.r-content{
			padding: 20px;
			/deep/ .el-image{
				margin-right: 20px;
			}
		}
	}
</style>
