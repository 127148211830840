<template>
	<el-dialog
	    width="800px"
		@close="close"
		@open="open"
	  >
		<div class="a">
			<div class="a-title">查看物流</div>
			<div class="a-top">
				<div class="a-t-row">物流公司：{{wuliu.comText}}</div>
				<div class="a-t-row">物流单号：{{wuliu.nu}}</div>
			</div>
			<div class="a-content">
				<div class="a-item" v-for="(item,index) in wuliu.data" :key="index">
					<div class="a-item-line"></div>
					<div class="a-item-m">
						<div class="a-item-time">{{item.time}}</div>
						<div class="a-item-text">{{item.context}}</div>
					</div>
				</div>
				<div class="a-item" v-if="wuliu.data.length"></div>
			</div>
			<div class="a-footer">
				<el-button style="margin-right: 20px;" size="mini" @click="close">取消</el-button>
				<el-button style="margin-left: 20px;" size="mini" type="primary" @click="close">确认</el-button>
			</div>
		</div>
	</el-dialog>
</template>

<script>
	import { mapActions, mapState } from 'vuex'
	import { ElMessage } from 'element-plus'
	export default{
		data(){
			return{
				activeNav:1,
				wuliu:{data:[]},
			}
		},
		props:['info'],
		methods:{
			...mapActions('mysaleMudule',[
				'purchaseGetExpress', //查看物流
			]),
			close(){ //关闭弹框
				this.clearData();
				this.$emit('update:modelValue', false);
			},
			getLogistics(){ //获取物流信息
				this.purchaseGetExpress({
					courierNumber:this.info.courierNumber || '',
					courierCompany:this.info.courierCompany || '',
					courierPhone:this.info.courierCompany === 'shunfeng' ? this.info.courierPhone : undefined,
				}).then(res => {
					let {code,data} = res.data;
					if(code === '0'){
						if(data.status === '200'){
							data.comText = this.courierCompany.filter(v => v.type === data.com).length ? 
							this.courierCompany.filter(v => v.type === data.com)[0].title : '';
							this.wuliu = data;
						}else{
							ElMessage({
								type:'error',
								message:data.message,
							})
						}
					}
				})
			},
			clearData(){ //清空数据
				this.wuliu = {data:[]};
			},
			open(){
				this.getLogistics();
			}
		},
		computed:{
			...mapState('purchaseMudule',[
				'courierCompany',
			]),
		},
		created(){
			
		}
	}
</script>

<style scoped lang="less">
	.a{
		font-size: 14px;
		padding: 20px;
		.a-title{
			font-weight: bold;
			padding: 0 20px 20px 0;
		}
		.a-footer{
			display: flex;
			justify-content: center;
		}
		.a-content{
			height: 500px;
			box-sizing: border-box;
			overflow-y: auto;
			padding: 10px 0;
		}
		.a-item{
			display: flex;
			position: relative;
			box-sizing: border-box;
			padding-left: 40px;
			.a-item-m{
				box-sizing: border-box;
				padding: 10px 0;
				.a-item-time{
					font-size: 10px;
					color: #999999;
				}
				.a-item-text{
					box-sizing: border-box;
					width: 500px;
					padding: 10px 20px;
				}
			}
		}
		.a-item::after,
		.a-item::before{
			border-radius: 50%;
			position: absolute;
			content: "";
			left: 20px;
			transform: translateX(-50%);
		}
		.a-item::after{
			width: 4px;
			height: 4px;
			top: 4px;
			z-index: 100;
			background: #FFA500;
		}
		.a-item::before{
			width: 8px;
			height: 8px;
			border: 2px solid #FFA500;;
		}
		.a-item-line{
			position: absolute;
			left: 20px;
			width: 2px;
			transform: translateX(-50%);
			top: 12px;
			bottom: 0px;
			background: #FFA500;
		}
		.a-top{
			padding-bottom: 20px;
			.a-t-row{
				padding-bottom: 10px;
				padding-left: 20px;
				color: #616161;
			}
			.a-t-row:last-of-type{
				padding-bottom: 0;
			}
		}
	}
</style>
