<template>
	<div class="c">
		<div class="c-item" v-for="(item,index) in files" :key="index">
			<span @click="open(item)">{{item.fileName}}</span>
			<el-button @click="download(item)" type="primary" size="mini">下载</el-button>
		</div>
	</div>
</template>

<script>
	import { downLoadFile } from '@/common/js/common.js'
	export default{
		data(){
			return{
				files:[],  //文件列表
			}
		},
		props:['orderInfo','info'],
		created(){
			this.init();
		},
		methods:{
			init(){
				this.files = [
					...this.orderInfo.contractFiles,
					...this.orderInfo.backContractFiles,
				];
			},
			download(row){ //下载
				downLoadFile(row.url,row.fileName);
			},
			open(row){ //直接打开
				window.open(row.url);
			}
		},
		watch:{
			orderInfo(){
				this.init();
			}
		}
	}
</script>

<style scoped lang="less">
	.c{
		.c-item{
			display: flex;
			align-items: center;
			padding: 10px 0;
			span{
				color: #0A25FF;
				margin-right: 10px;
				text-decoration: underline;
				cursor: pointer;
			}
		}
	}
</style>
