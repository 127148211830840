<template>
  <el-dialog
      width="600px"
  >
    <div class="a">
      <div class="a-title">{{ title }}</div>
      <div class="a-item">
        <span><i>*</i>采购合同</span>
        <div class="a-item-center">
          <el-upload
              class="upload-demo"
              :action="getAction"
              :headers="getHeaders"
              :show-file-list="true"
              :on-success="onSuccess"
              :limit="1"
              :before-upload="beforeAvatarUpload"
          >
            <el-button size="small" type="text">选择文件</el-button>
            <!-- <div class="up-img">
              <img v-if="imageUrl" :src="imageUrl" class="avatar" />
              <p class="iconfont icon-jiahao" v-else></p>
            </div> -->
          </el-upload>
        </div>
      </div>
      <div class="a-item">
        <span>备注</span>
        <el-input
            v-model="value"
            placeholder="请输入"
            :rows="6"
            show-word-limit
            type="textarea"
            style="flex: 1;"
        />
      </div>
      <div class="a-footer">
        <el-button style="margin-right: 20px;" size="mini" @click="close">取消</el-button>
        <el-button style="margin-left: 20px;" size="mini" type="primary" @click="confirm">上传</el-button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import request from "@/common/js/request.js"
import api from "@/common/js/api.js"
import {mapState} from "vuex"
import {ElMessage} from 'element-plus'
import {getBase64} from '@/common/js/common.js'
import {uploadFileOnSuccess} from "@/common/js/uploadFile.js"

export default {
  data() {
    return {
      imageUrl: '', //文件临时路径
      ids: [],  //图片ids
      value: '',  //备注
    }
  },
  props: ['title'],
  computed: {
    ...mapState([
      'token', //获取token
    ]),
    getAction() { //上传文件地址
      return request.defaults.baseURL + api.uploadFile + '?fileType=CUSTOMER';
    },
    getHeaders() { //请求头部
      return {
        'Authorization': this.token || null,
      }
    }
  },
  methods: {
    close() { //关闭弹框
      this.$emit('update:modelValue', false);
    },
    confirm() {
      if (this.ids.length) {
        this.$emit('success', {
          ids: this.ids,
          value: this.value,
        })
      } else {
        ElMessage({
          type: 'warning',
          message: '请选择合同',
        })
      }
    },
    onSuccess(response, file, fileList) { //监听文件上传成功
      uploadFileOnSuccess(response, file, fileList).then(res => {
        this.ids = [response.data.fileId];  //图片id
        getBase64(file.raw).then(res => {
          this.imageUrl = res;
        })
      }).catch(res => {
      })
    },
    beforeAvatarUpload(file) { //判断文件大小
      const isLt3M = file.size / 1024 / 1024 < 3;
      if (!isLt3M) {
        this.$message.error('单个文件大小不能超过3MB!');
      }
      return isLt3M;
    },
  }
}
</script>

<style scoped lang="less">
.a {
  font-size: 14px;
  padding: 20px;

  .a-title {
    font-weight: bold;
    padding: 0 20px 20px 0;
  }

  .a-footer {
    display: flex;
    justify-content: center;
  }

  .a-item {
    display: flex;
    align-items: center;
    padding-bottom: 20px;

    .a-item-center {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    span {
      width: 100px;
      margin-right: 20px;
      text-align: right;

      i {
        margin-right: 5px;
        color: #D84146;
      }
    }
  }

  .up-img {
    width: 180px;
    height: 180px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #FBFDFF;
    border-radius: 10px;
    border: 1px solid #eeeeee;
    overflow: hidden;

    p {
      font-size: 40px;
      color: #999999;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
</style>
